@import "settings/variables";
@import "settings/mixins";
@import "settings/typography";

// Core variables and mixins
@import "~bootstrap-sass/assets/stylesheets/bootstrap/mixins";

// Reset and dependencies
@import "~bootstrap-sass/assets/stylesheets/bootstrap/normalize";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/print";

// Core CSS
@import "~bootstrap-sass/assets/stylesheets/bootstrap/scaffolding";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/grid";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/tables";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/forms";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/buttons";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/button-groups";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/type";

// Components
@import "~bootstrap-sass/assets/stylesheets/bootstrap/alerts";

// Utility classes
@import "~bootstrap-sass/assets/stylesheets/bootstrap/utilities";
@import "~bootstrap-sass/assets/stylesheets/bootstrap/responsive-utilities";

// Common Elements
@import "elements/header";
@import "elements/footer";

// Blocks
@import "blocks/autonav/view";
@import "blocks/image_slider/view";
@import "blocks/community_product_list/view";

// Layouts
@import "layouts/home";
@import "layouts/full";

html {
  box-sizing: border-box;
  font-size: 16px !important;

  @include media($screen-sm, max) {
    font-size: 14px !important;
  }
}
*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  font-family: $font-primary;
  margin: 0;
}