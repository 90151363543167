.page-template-home {

  .header {
    border-bottom: 1px solid rgba(white, 0.3);
  }

  .autonav {

    &__link {
      color: white;

      &:hover {
        color: white;
      }
    }
  }

  .hero {
    position: relative;
    z-index: 10;
    margin-top: -140px;
  }

  .services {
    padding: 2rem 0;
    background-color: $brand-yellow;
    color: white;

    h1, h2, h3, h4, h5, h6 {
      margin: 0.5rem 0;
      font-weight: 700;
    }

    h4 {
      font-size: 1.2rem;
    }
  }

  .statement {
    background-image: url("../images/home/statement-bg.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    h1, h2, h3, h4, h5, h6 {
      color: $brand-brown;
    }

    p {
      color: $brand-gray;
      font-size: 1.1rem;
    }

    h3 {
      font-size: 2.2rem;
    }

    h4, h5, h6 {
      margin: 0;
    }

    &__inner {
      @include wrapper;
      padding: 4rem 0;
    }

    &__content {
      max-width: 630px;
    }
  }

  .products {
    @include wrapper();
    padding: 3rem 0;

    h1, h2, h3, h4, h5, h6 {
      color: $brand-brown;
      font-weight: 600;
    }

    h3 {
      font-size: 2rem;
    }
  }

  .cta {
    color: white;
    background-image: url("../images/home/cta-bg.png");
    background-size: cover;
    background-position: bottom center;
    background-repeat: no-repeat;
    padding: 3rem 0;
    font-size: 1.1rem;

    a {
      color: white;
      text-transform: uppercase;
      padding: 0.8rem 2rem;
      background-color: $brand-yellow;
      border-radius: 1.5rem;
      transition: 0.3s ease background-color;
      font-size: 1.1rem;
      font-weight: 600;
      white-space: nowrap;

      &:hover {
        text-decoration: none;
        background-color: darken($brand-yellow, 10);
      }
    }
  }

  &.is-edit-mode {

  }
}