@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-Black.eot');
  src: local('Roboto Slab Black'), local('RobotoSlab-Black'),
  url('../fonts/RobotoSlab/RobotoSlab-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-Black.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-Black.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-Black.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-Black.svg#RobotoSlab-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-Thin.eot');
  src: local('Roboto Slab Thin'), local('RobotoSlab-Thin'),
  url('../fonts/RobotoSlab/RobotoSlab-Thin.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-Thin.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-Thin.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-Thin.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-Thin.svg#RobotoSlab-Thin') format('svg');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-ExtraLight.eot');
  src: local('Roboto Slab ExtraLight'), local('RobotoSlab-ExtraLight'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraLight.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraLight.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraLight.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraLight.svg#RobotoSlab-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-Bold.eot');
  src: local('Roboto Slab Bold'), local('RobotoSlab-Bold'),
  url('../fonts/RobotoSlab/RobotoSlab-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-Bold.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-Bold.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-Bold.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-Bold.svg#RobotoSlab-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-SemiBold.eot');
  src: local('Roboto Slab SemiBold'), local('RobotoSlab-SemiBold'),
  url('../fonts/RobotoSlab/RobotoSlab-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-SemiBold.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-SemiBold.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-SemiBold.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-SemiBold.svg#RobotoSlab-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-Medium.eot');
  src: local('Roboto Slab Medium'), local('RobotoSlab-Medium'),
  url('../fonts/RobotoSlab/RobotoSlab-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-Medium.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-Medium.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-Medium.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-Medium.svg#RobotoSlab-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-ExtraBold.eot');
  src: local('Roboto Slab ExtraBold'), local('RobotoSlab-ExtraBold'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraBold.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraBold.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraBold.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-ExtraBold.svg#RobotoSlab-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-Regular.eot');
  src: local('Roboto Slab Regular'), local('RobotoSlab-Regular'),
  url('../fonts/RobotoSlab/RobotoSlab-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-Regular.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-Regular.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-Regular.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-Regular.svg#RobotoSlab-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto Slab';
  src: url('../fonts/RobotoSlab/RobotoSlab-Light.eot');
  src: local('Roboto Slab Light'), local('RobotoSlab-Light'),
  url('../fonts/RobotoSlab/RobotoSlab-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/RobotoSlab/RobotoSlab-Light.woff2') format('woff2'),
  url('../fonts/RobotoSlab/RobotoSlab-Light.woff') format('woff'),
  url('../fonts/RobotoSlab/RobotoSlab-Light.ttf') format('truetype'),
  url('../fonts/RobotoSlab/RobotoSlab-Light.svg#RobotoSlab-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-BlackItalic.eot');
  src: local('Crimson Pro Black Italic'), local('CrimsonPro-BlackItalic'),
  url('../fonts/CrimsonPro/CrimsonPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-BlackItalic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-BlackItalic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-BlackItalic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-BlackItalic.svg#CrimsonPro-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-Medium.eot');
  src: local('Crimson Pro Medium'), local('CrimsonPro-Medium'),
  url('../fonts/CrimsonPro/CrimsonPro-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-Medium.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-Medium.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-Medium.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-Medium.svg#CrimsonPro-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-SemiBoldItalic.eot');
  src: local('Crimson Pro SemiBold Italic'), local('CrimsonPro-SemiBoldItalic'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBoldItalic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBoldItalic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBoldItalic.svg#CrimsonPro-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-Regular.eot');
  src: local('Crimson Pro Regular'), local('CrimsonPro-Regular'),
  url('../fonts/CrimsonPro/CrimsonPro-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-Regular.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-Regular.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-Regular.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-Regular.svg#CrimsonPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-Light.eot');
  src: local('Crimson Pro Light'), local('CrimsonPro-Light'),
  url('../fonts/CrimsonPro/CrimsonPro-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-Light.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-Light.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-Light.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-Light.svg#CrimsonPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-SemiBold.eot');
  src: local('Crimson Pro SemiBold'), local('CrimsonPro-SemiBold'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBold.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBold.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBold.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-SemiBold.svg#CrimsonPro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-ExtraLightItalic.eot');
  src: local('Crimson Pro ExtraLight Italic'), local('CrimsonPro-ExtraLightItalic'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLightItalic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLightItalic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLightItalic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLightItalic.svg#CrimsonPro-ExtraLightItalic') format('svg');
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-LightItalic.eot');
  src: local('Crimson Pro Light Italic'), local('CrimsonPro-LightItalic'),
  url('../fonts/CrimsonPro/CrimsonPro-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-LightItalic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-LightItalic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-LightItalic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-LightItalic.svg#CrimsonPro-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-MediumItalic.eot');
  src: local('Crimson Pro Medium Italic'), local('CrimsonPro-MediumItalic'),
  url('../fonts/CrimsonPro/CrimsonPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-MediumItalic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-MediumItalic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-MediumItalic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-MediumItalic.svg#CrimsonPro-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-BoldItalic.eot');
  src: local('Crimson Pro Bold Italic'), local('CrimsonPro-BoldItalic'),
  url('../fonts/CrimsonPro/CrimsonPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-BoldItalic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-BoldItalic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-BoldItalic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-BoldItalic.svg#CrimsonPro-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-Italic.eot');
  src: local('Crimson Pro Italic'), local('CrimsonPro-Italic'),
  url('../fonts/CrimsonPro/CrimsonPro-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-Italic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-Italic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-Italic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-Italic.svg#CrimsonPro-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-ExtraLight.eot');
  src: local('Crimson Pro ExtraLight'), local('CrimsonPro-ExtraLight'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLight.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLight.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLight.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraLight.svg#CrimsonPro-ExtraLight') format('svg');
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-ExtraBold.eot');
  src: local('Crimson Pro ExtraBold'), local('CrimsonPro-ExtraBold'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBold.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBold.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBold.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBold.svg#CrimsonPro-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-Bold.eot');
  src: local('Crimson Pro Bold'), local('CrimsonPro-Bold'),
  url('../fonts/CrimsonPro/CrimsonPro-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-Bold.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-Bold.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-Bold.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-Bold.svg#CrimsonPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-Black.eot');
  src: local('Crimson Pro Black'), local('CrimsonPro-Black'),
  url('../fonts/CrimsonPro/CrimsonPro-Black.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-Black.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-Black.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-Black.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-Black.svg#CrimsonPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Crimson Pro';
  src: url('../fonts/CrimsonPro/CrimsonPro-ExtraBoldItalic.eot');
  src: local('Crimson Pro ExtraBold Italic'), local('CrimsonPro-ExtraBoldItalic'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBoldItalic.woff') format('woff'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBoldItalic.ttf') format('truetype'),
  url('../fonts/CrimsonPro/CrimsonPro-ExtraBoldItalic.svg#CrimsonPro-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Medium.eot');
  src: local('Open Sans Medium'), local('OpenSans-Medium'),
  url('../fonts/OpenSans/OpenSans-Medium.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Medium.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-Medium.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Medium.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-Medium.svg#OpenSans-Medium') format('svg');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-BoldItalic.eot');
  src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-BoldItalic.svg#OpenSans-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-MediumItalic.eot');
  src: local('Open Sans Medium Italic'), local('OpenSans-MediumItalic'),
  url('../fonts/OpenSans/OpenSans-MediumItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-MediumItalic.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-MediumItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-MediumItalic.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-MediumItalic.svg#OpenSans-MediumItalic') format('svg');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Italic.eot');
  src: local('Open Sans Italic'), local('OpenSans-Italic'),
  url('../fonts/OpenSans/OpenSans-Italic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Italic.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-Italic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Italic.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-Italic.svg#OpenSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-SemiBold.eot');
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
  url('../fonts/OpenSans/OpenSans-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-SemiBold.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-SemiBold.svg#OpenSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Light.eot');
  src: local('Open Sans Light'), local('OpenSans-Light'),
  url('../fonts/OpenSans/OpenSans-Light.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-Light.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Light.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-Light.svg#OpenSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Regular.eot');
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
  url('../fonts/OpenSans/OpenSans-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Regular.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-Regular.svg#OpenSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-Bold.eot');
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
  url('../fonts/OpenSans/OpenSans-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-Bold.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-Bold.svg#OpenSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot');
  src: local('Open Sans ExtraBold Italic'), local('OpenSans-ExtraBoldItalic'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-ExtraBoldItalic.svg#OpenSans-ExtraBoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-LightItalic.eot');
  src: local('Open Sans Light Italic'), local('OpenSans-LightItalic'),
  url('../fonts/OpenSans/OpenSans-LightItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-LightItalic.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-LightItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-LightItalic.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-LightItalic.svg#OpenSans-LightItalic') format('svg');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-ExtraBold.eot');
  src: local('Open Sans ExtraBold'), local('OpenSans-ExtraBold'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-ExtraBold.svg#OpenSans-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url('../fonts/OpenSans/OpenSans-SemiBoldItalic.eot');
  src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.woff2') format('woff2'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.woff') format('woff'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.ttf') format('truetype'),
  url('../fonts/OpenSans/OpenSans-SemiBoldItalic.svg#OpenSans-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

$font-primary: 'Open Sans', Helvetica, Arial, Verdana, Tahoma, sans-serif;
$font-secondary: 'Roboto Slab', Helvetica, Arial, Verdana, Tahoma, sans-serif;

h1 {
  font-size: 5rem;
  line-height: 6rem;
  font-weight: 700;
  margin: 1rem 0;
}

h2 {
  margin: 1rem 0;
  font-size: 3.4rem;
  font-weight: 300;
}

h3 {
  margin: 1rem 0;
  font-size: 2.3rem;
  font-weight: 300;
  line-height: 2.2rem;
}

h4 {
  margin:  1rem 0;
  font-size: 1.4rem;
  font-weight: 500;
}

.title {

  &__main {
    font-family: $font-secondary;
  }

  &__sub {
    font-family: 'Crimson Pro', Helvetica, Arial, Verdana, Tahoma, sans-serif;
  }
}



