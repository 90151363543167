.autonav {
  display: flex;
  margin: 0 0 -9px 0;
  padding: 0;
  list-style: none;
  transform: translateX(-68px);

  @include media(994px, max) {
    transform: translateX(0);
  }

  @include media($top-nav-breakpoint, max) {
    display: none;
  }

  &__item {
    font-family: $font-secondary;
    font-size: 20px;
    font-weight: 800;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    &:hover {

    }

    &_selected {

    }

    &_path-selected {

    }

    &_has-dropdown {

    }
  }

  &__cart-link {
    transform: translateY(6px);
    display: block;
    width: 19px;
    height: 18px;
    background-image: url("../images/elements/header/cart-icon.png");
    background-size: 19px 18px;
    background-repeat: no-repeat;

    @include media($top-nav-breakpoint, max) {
      width: initial;
      height: initial;
      background: none;
      transform: translateY(0);
    }

    span {
      display: none;

      @include media($top-nav-breakpoint, max) {
        display: inline;
      }
    }
  }

  &__link {
    color: $brand-brown;
    text-decoration: none;
    display: block;
    transition: 0.3s color ease;

    &:hover, &:active, &:visited {
      color: $brand-brown;
      text-decoration: none;
    }

    &_selected {

    }

    &_path-selected {

    }

    &_has-dropdown {
      position: relative;
      z-index: 30;
    }
  }
}

.ccm-page {

  &.is-edit-mode {

    .autonav {

      &__link {
        color: black;
      }
    }
  }
}