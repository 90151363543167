.hero-slider {
  position: relative;

  &__wrapper {

  }

  &__slides {

  }

  &__slide {

    img {
      max-height: 718px;
      object-fit: cover;
      min-height: 600px;
    }
  }

  &__text {
    color: white;
    width: 100%;
    max-width: 800px;
    position: absolute;
    top: 430px;
    left: 50%;
    transform: translate(-50%, -50%);

    @include media($screen-sm, max) {
      top: 400px;
    }

    h1 {
      font-size: 5rem;
      transform: translateY(-70px);
      line-height: 5.6rem;

      @include media($screen-sm, max) {
        font-size: 3.5rem;
        line-height: 4rem;
        transform: translateY(-30px);
      }
    }

    h2 {
      transform: translateY(-33px);

      @include media($screen-sm, max) {
        transform: translateY(0);
      }
    }

    h3 {
      transform: translateY(-100px);

      @include media($screen-sm, max) {
        transform: translateY(-50px);
      }
    }

    img {
      display: inline !important;
      float: none !important;
      width: auto !important;
    }
  }

  &__title {

  }
}