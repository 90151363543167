.tg-product-list {
  margin-top: 3rem;
  margin-bottom: 3rem;

  &__grid {
    display: grid;
    grid-gap: 1rem;

    @include media($top-nav-breakpoint, max) {
      grid-template-columns: repeat(auto-fit, minmax(180px, 1fr)) !important;
    }
  }

  &__thumbnail {
    display: flex;
    justify-content: center;
  }

  &__name {
    color: $brand-gray;
    text-align: center;
  }

  &__price {
    color: $brand-brown-md;
    text-align: center;
    font-weight: 800;
    font-family: $font-secondary;
    font-size: 1.1rem;
    letter-spacing: 1px;
  }

  .store-btn-add-to-cart-container {
    display: flex;
    justify-content: center;
    margin-top: 2rem;

    .store-btn-add-to-cart {
      border: 1px solid lighten($brand-gray, 30);
      background-color: white;
      text-transform: uppercase;
      padding: 0.5rem 2rem;
      border-radius: 1.5rem;
      color: $brand-gray;
      transition: 0.3s color ease, 0.3s background-color ease;

      &:hover {
        color: black;
        background-color: $brand-yellow;
        border-color: $brand-yellow;
      }
    }
  }

  &__shop-all-block {
    text-align: center;
    margin: 2rem 0 3rem 0;

    a {
      color: white;
      text-transform: uppercase;
      font-weight: 700;
      background-color: #a2473e;
      padding: 1rem 4rem;
      border-radius: 1.5rem;
      font-size: 0.8rem;
      transition: 0.3s ease background-color;

      &:hover{
        text-decoration: none;
        background-color: $brand-brown;
      }
    }
  }
}