.testimonials-slider {
  position: relative;

  &__wrapper {

  }

  &__slides {

  }

  &__slide {

    img {
      max-height: 718px;
      object-fit: cover;
      min-height: 600px;
    }
  }

  &__text {
    color: white;
    width: 100%;
    max-width: 980px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    img {
      display: inline !important;
      float: none !important;
      width: auto !important;
    }

    h3 {
      margin: 3rem 0;
    }
  }

  &__title {

  }
}